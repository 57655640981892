import { render, staticRenderFns } from "./HistoryList.vue?vue&type=template&id=f0f22004&scoped=true"
import script from "./HistoryList.vue?vue&type=script&lang=js"
export * from "./HistoryList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0f22004",
  null
  
)

export default component.exports