<template>
  <div class="card">
    <div id="active-contracts-card" class="card-body p-0 py-4">
      <div class="d-flex justify-content-between align-items-center px-4 pb-3">
        <h2 class="m-0">Upload contract</h2>
        <div class="d-flex flex-row justify-content-between align-items-center">
          <div>Solidity version:</div>
          <vs-select
            placeholder="Select solidity version"
            v-model="contractConfig.compilerVersion"
            v-if="Object.keys(contractConfig.compilerVersions).length > 0"
          >
            <vs-option
              v-for="version in Object.keys(contractConfig.compilerVersions)"
              :key="version"
              :label="version"
              :value="contractConfig.compilerVersions[version]"
            >
              {{ version }}
            </vs-option>
          </vs-select>
          <div
            class="button-text d-flex justify-content-center align-items-center"
          >
            <a style="width: 80px" @click="handleCompile()" class="btn-theme"
              >Upload</a
            >
          </div>
        </div>
      </div>
      <div class="card-body">
        <vue-monaco-editor
          class="form-control"
          style="padding:0px!important;"
          v-model="contractConfig.code"
          theme="vs-light"
          height="400px"
          language="sol"
          :options="{
            automaticLayout: true,
            minimap: { enabled: false },
          }"
        />
      </div>
      <div
        class="card-body"
        v-if="Object.keys(contractConfig.compiledContracts).length > 0"
      >
        <div class="w-100 d-flex justify-content-between">
          <vs-select
            placeholder="Select contract name"
            v-model="contractConfig.contractName"
          >
            <vs-option
              v-for="name in Object.keys(contractConfig.compiledContracts)"
              :key="name"
              :label="name"
              :value="name"
            >
              {{ name }}
            </vs-option>
          </vs-select>
          {{ contractConfig.deployedContract }}
        </div>
        <div>{{ status }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex';
// import Utils from '@/helpers/Utils';
import Web3 from '@/helpers/Web3';
// import Zerion from '@/helpers/Zerion';
// import Vue from 'vue';
// import Sample from './sample.json';
import { VueMonacoEditor } from '@guolao/vue-monaco-editor'
import { getCompilerVersions } from '@agnostico/browser-solidity-compiler';

export default {
  name: 'ContractEditor',
  components: { VueMonacoEditor },
  computed: {
    ...mapGetters({
      liveList: 'watches/liveList',
      pastList: 'watches/pastList',
      contractConfig: 'contracts/config',
    }),
  },
  watch: {},
  async mounted() {
    this.setCompilerVersions((await getCompilerVersions()).releases);
  },
  beforeDestroy() {},
  methods: {
    ...mapActions({
      fetch: 'watches/fetch',
      delete: 'watches/delete',
      setContractName: 'contracts/setContractName',
      setCompilerVersions: 'contracts/setCompilerVersions',
      compileContract: 'contracts/compileContract',
      setDeployedContract: 'contracts/setDeployedContract',
    }),
    getNetwork() {
      return Web3.getNetwork();
    },
    async handleCompile() {
      this.compileContract();
    },
  },
  data() {
    return {
      isVerifying: false,
      status: '',
    };
  },
};
</script>

<style scoped></style>
